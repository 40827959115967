import React, { useState, useEffect, useRef } from 'react';
import { FaBars, FaTimes } from "react-icons/fa";
import img1 from './assets/img1.jpg';
import img2 from './assets/Quartz.jpeg';
import img3 from './assets/Feldspar.jpg';
import img4 from './assets/acidic-rammingmass.jpg';
import img5 from './assets/img5.jpg';
import img6 from './assets/innovation2.png';
import img7 from './assets/expert.png';
import img8 from './assets/safety.png';
import img9 from './assets/logo.png';
import img10 from './assets/img10.jpg';
import { IoLocationOutline } from "react-icons/io5";
import { IoCallOutline } from "react-icons/io5";
import { IoMdPaperPlane } from "react-icons/io";
import { IoHomeOutline } from "react-icons/io5";
import { GiMiningHelmet } from "react-icons/gi";
import { IoDocumentOutline } from "react-icons/io5";
import { BsPatchQuestion } from "react-icons/bs";
import { LuPhone } from "react-icons/lu";
import { ArrowUp } from "lucide-react";
import './main.css';

const HomePage = () => {
	const navRef = useRef();
	const [showNav, setShowNav] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [isRightAnimation, setIsRightAnimation] = useState(false);

	const showNavbar = () => {
		setShowNav(!showNav);
	};

	const scrollToSection = (id) => {
		const section = document.getElementById(id);
		if (section) {
			section.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'nearest',
				duration: 10000,
			});
			setShowNav(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		if (
			document.body.scrollTop > 20 ||
			document.documentElement.scrollTop > 20
		) {
			setShowButton(true);
		} else {
			setShowButton(false);
		}
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	const handleClick = () => {
		scrollToTop();
		setTimeout(() => {
			document.getElementById("mytopBtn").classList.remove("clicked");
		}, 1000);
	};

	const topFunction = () => {
		scrollToTop();
	};

	const toggleAnimation = () => {
		setIsRightAnimation((prev) => !prev);
	};

	return (
		<div>
			<header>
				<img src={img9} onClick={() => scrollToSection('home')}/>
				<h1 onClick={() => scrollToSection('home')}>Shiva Minerals</h1>
				<nav ref={navRef} className={showNav ? "responsive_nav" : ""}>
					<div>
						<IoHomeOutline />
						<a onClick={() => scrollToSection('home')}>Home</a>
					</div>
					<div>
						<GiMiningHelmet />
						<a onClick={() => scrollToSection('services')}>Our Services</a>
					</div>
					<div>
						<IoDocumentOutline />
						<a onClick={() => scrollToSection('aboutUs')}>About Us</a>
					</div>
					<div>
						<BsPatchQuestion />
						<a onClick={() => scrollToSection('whyChooseUS')}>Why Choose Us</a>
					</div>
					<div>
						<LuPhone />
						<a onClick={() => scrollToSection('contact')}>Contact Us</a>
					</div>
					<button className="nav-btn nav-close-btn" onClick={showNavbar}>
						<FaTimes />
					</button>
				</nav>
				<button className="nav-btn nav-bars-btn" onClick={showNavbar}>
					<FaBars />
				</button>
			</header>

			<div className='home-container' id='home'>
				<img src={img1} className='home-img-phone' />
				<img src={img10} className='home-img-desktop' />
				<div className='home-inner-container'>
					<h1>Shiva Minerals</h1>
					<h2>A renowned mining company specializing in the extraction and processing of high-quality minerals. With state-of-the-art facilities and a commitment to sustainable practices, we deliver top-notch products while prioritizing environmental responsibility. Our expertise, integrity, and community engagement make us a trusted partner in the global minerals industry.</h2>
				</div>
			</div>

			<div className='services-container' id='services'>
				<h1>Our Services</h1>
				<div className="services-inner-container">

					<div className="card-content">
						<img src={img2} className='service-img' alt='Quartz' />
						<div className="card-desc">
							<h2>Quartz</h2>
							
								<ul>
									<li>High hardness and durability</li>
									<li>Exceptional chemical resistance</li>
									<li>Wide range of vibrant colors</li>
									<li>Excellent clarity and transparency</li>
									<li>Integral component in electronics</li>
								</ul>
							
						</div>
					</div>
					<div className="card-content reverse">
						<img src={img4} className='service-img' alt='Acidic Ramming Mass' />
						<div className="card-desc">
							<h2>Acidic Ramming Mass</h2>
							
								<ul>
									<li>Superior thermal stability</li>
									<li>Resistant to chemical reactions</li>
									<li>Ideal for furnace linings</li>
									<li>Enhances steel production efficiency</li>
									<li>Prolongs equipment lifespan</li>
								</ul>
							
						</div>
					</div>
					<div className="card-content">
						<img src={img3} className='service-img' alt='FeldSpar' />
						<div className="card-desc">
							<h2>FeldSpar</h2>
							
								<ul>
									<li>Lowers glass melting point</li>
									<li>Promotes ceramic uniformity</li>
									<li>Strengthens ceramic and enamel</li>
									<li>Versatile in industrial applications</li>
									<li>Essential in paint formulations</li>
								</ul>
							
						</div>
					</div>
				</div>
			</div>

			<div className='about-container' id='aboutUs'>
				<h1>About US</h1>
				<div className='about-inner-container'>
					<img src={img5} />
					<div className='about-inner-para'>
						<h6>Welcome to Shiva Minerals</h6>
						<p>
							A leading mineral extracting company
							dedicated to sustainable and responsible resource extraction. With a
							rich legacy spanning 10 years, we have been at the forefront
							of technological innovation and environmental stewardship in the
							mining industry.
						</p>
						<p>
							Our mission is to extract valuable minerals ethically and
							efficiently while minimizing environmental impact. We prioritize
							safety, integrity, and community engagement, striving to create
							lasting value for our stakeholders and the regions where we operate.
						</p>
						<p>
							Through continuous improvement and a commitment to best practices,
							we aim to be a trusted partner in global resource development,
							driving economic growth and environmental sustainability hand in
							hand.
						</p>
					</div>
				</div>
			</div>

			<div className="why-container" id="whyChooseUS">
				<h1>Why Choose Us ?</h1>
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="main">
								<div className="service">
									<div className="service-logo">
										<img src={img7} alt="" />
									</div>
									<h4>Expertise</h4>
									<p>
										With over 10 years of experience in mineral extraction, our team possesses unparalleled expertise in navigating the complexities of the mining industry. From exploration to production, we bring a depth of knowledge that ensures efficient operations, optimal resource utilization, and exceptional outcomes for our clients.
									</p>
								</div>
								<div className="shadow1"></div>
								<div className="shadow2"></div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="main">
								<div className="service">
									<div className="service-logo">
										<img src={img6} alt="" />
									</div>
									<h4>Innovation</h4>
									<p>
										Embracing innovation is at the core of our operations. We invest in state-of-the-art technologies and sustainable practices to revolutionize the way we extract minerals. By integrating advanced methodologies, we not only enhance productivity but also reduce our environmental footprint, paving the way for a more sustainable future.
									</p>
								</div>
								<div className="shadow1"></div>
								<div className="shadow2"></div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="main">
								<div className="service">
									<div className="service-logo">
										<img src={img8} alt="" style={{ marginTop: '5px' }} />
									</div>
									<h4>Commitment to Safety</h4>
									<p>
										Safety is non-negotiable in our work ethos. We prioritize the well-being of our employees, communities, and ecosystems by adhering to stringent safety standards and protocols. Continuous training, risk assessments, and proactive safety measures ensure a secure work environment, fostering trust and confidence among our stakeholders.
									</p>
								</div>
								<div className="shadow1"></div>
								<div className="shadow2"></div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="google-map">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d57388.7015627304!2d75.11174252828452!3d25.974800567635036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396ec8806d6d8029%3A0x66faf31347aea113!2sShiva%20Minerals!5e0!3m2!1sen!2sin!4v1710872448574!5m2!1sen!2sin"
				// width="600"
				// height="450"
				></iframe>
			</div>

			<div className="msg-container">
				<div className="msg-icons" onClick={toggleAnimation}>
					<i
						className="fa-solid fa-arrow-right-long msg-icon-1"
						style={{
							color: "white",
							display: isRightAnimation ? "none" : "block",
						}}
					></i>
					<i
						className="fa-solid fa-arrow-left-long msg-icon-2"
						style={{
							color: "white",
							display: isRightAnimation ? "block" : "none",
						}}
					></i>
				</div>
				<div id="msg-type"></div>
				<div className={`whatsapp animate-${isRightAnimation ? "4" : "3"}`}>
					<a href="https://wa.me/7877111300" target="_blank">
						<i className="fa-brands fa-whatsapp"></i>
					</a>
				</div>
				<div className={`sms animate-${isRightAnimation ? "4" : "3"}`}>
					<a href="sms:7877111300" target="_blank">
						<i className="fa-regular fa-message"></i>
					</a>
				</div>
			</div>

			{showButton && (
				<button onClick={topFunction} id="mytopBtn" title="Go to top">
					TOP <i data-lucide="move-up"></i>
				</button>
			)}

			<button
				onClick={topFunction && handleClick}
				id="mytopBtn"
				title="Go to top"
				className="scroll-to-top-button"
				style={{ display: showButton ? "flex" : "none" }}
			>
				TOP <ArrowUp />
			</button>

			<footer className='footer-container' id='contact'>
				<div className="footer-inner-div">
					<div className='footer-logo-div'>
						<img src={img9} />
					</div>
					<div>
						<h2>Our Services</h2>
						<ul>
							<li><a onClick={() => scrollToSection('services')}>Quartz</a></li>
							<li><a onClick={() => scrollToSection('services')}>Feldspar</a></li>
							<li><a onClick={() => scrollToSection('services')}>Acidic Ramming Mass</a></li>
						</ul>
					</div>
					<div className='footer-nav-section'>
						<h2>Quick Links</h2>
						<ul>
							<li>
								<IoHomeOutline />
								<a onClick={() => scrollToSection('home')}>Home</a>
							</li>
							<li>
								<IoDocumentOutline />
								<a onClick={() => scrollToSection('aboutUs')}>About Us</a>
							</li>
							<li>
								<BsPatchQuestion />
								<a onClick={() => scrollToSection('whyChooseUS')}>Why Choose Us</a>
							</li>
						</ul>
					</div>
					<div>
						<div>
							<h2>Contact Us</h2>
							<div className='footer-icons'>
								<IoLocationOutline />
								<h4>Shiva Minerals<br />G-110,111, 115,116 Riico Industrial Area, Kekri (Ajmer), 305404</h4>
							</div>
							<div className='footer-icons'>
								<IoCallOutline />
								<h4>+91-7877111300</h4>
							</div>
							<div className='footer-icons'>
								<IoMdPaperPlane />
								<h4>Shivaminerals@gmail.com</h4>
							</div>
						</div>
					</div>
				</div>
				<footer className="footer-third-div">
					<p>Copyright © 2024 All rights reserved | Made by <a href='https://www.softapper.com/'>Softapper</a></p>
				</footer>
			</footer>
		</div>
	)
}

export default HomePage